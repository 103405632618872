/* Import Google Font */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;600;700&display=swap");

/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", sans-serif;
  background-color: #f9f9f9;
  color: #333;
  font-weight: 300;
}

/* Page Layout */
.page-layout {
  padding: 20px 0;
}

/* Page Content */
.page-content {
  max-width: 500px;
  margin: 50px auto;
  padding: 40px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.page-content h1 {
  font-size: 22px;
  margin-bottom: 30px;
  text-align: center;
}

.page-content h2 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}

/* Loading Container */
.loading-container {
  text-align: center;
  padding: 50px 0;
}

/* Loading Spinner */
.loading-spinner {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}

.channel-logo {
  text-align: center;
}

.channel-logo img {
  max-width: 180px;
}

/* Header Styles */

/* Paragraph and List Styles */
.page-content p {
  line-height: 1.6;
}

.page-content ul {
  padding-left: 20px;
  margin: 10px 0;
}

.page-content ul li {
  margin-bottom: 10px;
}

.button-container {
  text-align: center;
  margin-top: 30px;
}

/* Button Styles */
.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #ff9b00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #e58a00;
}

/* Link Button Style */
.link-terms {
  border: none;
  background: none;
  cursor: pointer;
  font-weight: bold;
}

/* Error Message Style */
.error-message {
  color: red;
  text-align: center;
  font-weight: bold;
}

/* Iframe Style */
.iframe-terms {
  width: 100%;
  height: 100%;
  border: none;
}

/* Payment Page Container */
.payment-page-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Heading Style */
.payment-page-heading {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

/* Description Style */
.payment-page-description {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}

/* Checkout Container */
.checkout-container {
  width: 100%;
  min-width: 312px;
  background-color: transparent;
  border: none;
}
